<template>
<b-container :fluid="fluid" class="page pt-1 pt-lg-3 pl-1 pr-1 pl-lg-3 pr-lg-3" :id="id">
      <PageTitle :title="title"></PageTitle>
      <slot></slot>
</b-container>
</template>

<script>
const PageTitle = () => import("@/components/common/PageTitle");

export default {
      name: "Page",
      props: {
            "id": String,
            "title": String,
            fluid: Boolean
      },
      components: {
            PageTitle
      },
};
</script>
